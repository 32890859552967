import React from "react"

interface Props {
  pageContext: {
    redirect: string
  }
}

export default function RedirectTemplate(props: Props) {
  React.useEffect(() => {
    window.location.replace(props.pageContext.redirect)
  }, [])

  return (
    <div>
      <h1>Redirecting...</h1>
    </div>
  )
}
